import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//
//TABS
const tabTriggers = document.querySelectorAll('.tabs-nav span');

// click event on triggers
[...tabTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchTab);
});

// switch tabs
function switchTab(e) {
	e.preventDefault();
	let elTarget = e.currentTarget.dataset.tab;

	removeActive('.tabs-nav span[data-active]');
	removeActive('.tab[data-active]');

	//e.currentTarget.dataset.active = true;
	document.querySelector(
		`.tabs-nav span[data-tab=${elTarget}]`
	).dataset.active = true;
	document.querySelector(`.tab[data-tab=${elTarget}]`).dataset.active = true;
}

const swiperPartners = new Swiper('.swiper-partners', {
	// Optional parameters
	loop: true,
	slidesPerView: 'auto',
	spaceBetween: 60,
	speed: 2000,
	autoplay: {
		delay: 50
	}
});
const swiperPartnersExtra = new Swiper('.swiper-partners-extra', {
	// Optional parameters
	loop: true,
	slidesPerView: 'auto',
	spaceBetween: 60,
	speed: 2000,
	autoplay: {
		delay: 50
	}
});
